.password-prompt-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .password-prompt {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 400px;
    width: 100%;
  }
  
  .password-prompt h3 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .password-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  .password-prompt-actions {
    display: flex;
    justify-content: space-between;
  }
  
  .password-submit-btn,
  .password-cancel-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .password-submit-btn {
    width: 100%;
    background-color: #28a745;
    color: white;
  }
  
  .password-cancel-btn {
    width: 100%;
    background-color: #dc3545;
    color: white;
  }
  .filter-select {
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 14px;
    background-color: #f9f9f9;
    transition: background-color 0.3s ease;
  }
  .filter-select:hover {
    background-color: #f0f0f0;
  }