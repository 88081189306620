.Headtext{
  font-size: 22px;
  font-weight: 300;
}
.projectdiv {
  width: 100%;
  height: 100px;
  margin-bottom: 5px;
  padding: 10px 5px 0 10px;
}
.projectdiv1 {
  background: #EAEBFF;
}
.projectdiv2 {
  background: #F8E9FE;
}
.projectdiv3 {
  background: #F0CFEE;
}
.react-icon{
  color: #3c8dbc;
  margin-right: 10px !important;
  font-size: 70px;
  margin-top: -45px;
  transition: transform .7s  ;
}
.modal-wrapper{
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(189, 189, 189, 0.9);
  z-index: 999;
  display: flex;
  align-items: center;
   justify-content: center;
   overflow: auto;
}
.modal-container{
  position: fixed;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  overflow-y: auto;
  overflow-x: hidden;
  width: 95%;
  height: 90%;
  padding: 20px;
}
.modal-container::-webkit-scrollbar {
  width: 9px; 
}
.modal-container::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
.whiteback::-webkit-scrollbar-thumb {
  background-color: #636363 !important; 
  border-radius: 5px; 
}
.modal-container::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.closeicon{
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.coloureddiv {
  width: 100%;
  height: auto;
  max-height: 120px;
}
.coloureddiv1 {
  width: 100%;
  text-align: left;
}
.seemore{
  font-family: Kanit;
font-size: 16px;
font-weight: 400;
line-height: 23.92px;
text-align: left;
color: black !important;
}
.colouredtext {
 font-family: Kanit;
font-size: 20px;
font-weight: 400;
line-height: 29.9px;
text-align: left;

}
.datashow{
  border-radius: 50%;
  width: 40px;
height: 39px;
padding: 10px 8px 10px 10px;
cursor: pointer;
}
.datashow1{
  background: #9993FF;
}
.datashow2{
  background: #D990F9;
}
.datashow3{
  background: #F892BA;
}
.descriptiondiv {
  font-family: Kanit;
  font-size: 12px;
  font-weight: 300;
  line-height: 17.94px;
  text-align: left;
  
}
.totalunitsdiv {
  background-color: #f4f3f3;
  width: 200px;
  height: 150px;
  border-radius: 10px;
  border: 1px;
  margin-right: 30px;
}
.textunits {
  text-align: center;
}
.unitsnum {
  text-align: center;
  font-family: Poppins;
  font-size: 100px;
  font-weight: 275;
  line-height: 80px;
  letter-spacing: 0em;
}
.availableunitsdiv {
  background-color: #a6ffbf;
  width: 200px;
  height: 150px;
  border-radius: 10px;
  border: 1px;
  margin-right: 30px;
}
.holunitsdiv {
  background-color: #fee69f;
  width: 200px;
  height: 150px;
  border-radius: 10px;
  border: 1px;
  margin-right: 30px;
}
.solunitsdiv {
  background-color: #FF6384;
  width: 200px;
  height: 150px;
  border-radius: 10px;
  border: 1px;
}
.blockdiv{
  background-color: #F3F3F3;
  border: 1px darkgray solid;
}
.blockdivend{
  background-color: #F3F3F3;
  border: 1px darkgray solid;
  border-top-right-radius:  10px;
}
.blockdivstart{
  background-color: #F3F3F3;
  border: 1px darkgray solid;
  border-top-left-radius:  10px;
}
.bdiv{
  display: flex;
  grid-template-columns: repeat(6, 200px);
  grid-auto-rows: 50px;
}
.blockdiv1{
  font-size: 30px;
  text-align: center;
  background-color: azure;
  border: 1px #C7C7C7 solid;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.tablecursor{
  cursor: pointer;
}
.available-unit{
  width: 80px;
  height: 35px;
  text-align: center;
  border: 1px solid #E4E6E8;
  background-color: #A6FFBF;
}
.hold-unit{
  width: 60px;
  height: 35px;
  text-align: center;
  border: 1px solid #E4E6E8;
  background-color: #FEE69F;
}
.sold-unit{
  width: 60px;
  height: 35px;
  border: 1px solid #E4E6E8;
  text-align: center;
  background-color: #FE8B8B;
}
.delete-unit{
  width: 60px;
  height: 35px;
  border: 1px solid #E4E6E8;
  text-align: center;
  background-color: #f82821;
}
.unit-div{
text-align: center;
margin-top: 25%;
}
.units-div{
  gap: 20px;
  border: 1px solid #E4E6E8;
  width: 50px;
  height: 50px;
  margin-top: 10%;
  background-color: #FFFFFF;
  border-radius: 5px;
  cursor: pointer;
}
.unit-div-dropdown{
  gap: 10px;
    width: 220px;
    height: 50px;
    border: 1px solid #E4E6E8;
    margin-top: 5%;
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #FFFFFF;
    margin-left: -70%;
}
.user-dropdown{
  gap: 10px;
  width: 100px;
  height: 50px;
  border: 1px solid #E4E6E8;
  margin-top: 5%;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #FFFFFF;
  margin-left: -25%;
}
.upload-title{
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  color: #000000;
}

.form-input-field{
  width: 100%;
  height: 45px;
  border: 1px solid #C0C0C0;
  font-size: 14px;
  color: #000000;
  padding-left: 2%;
  margin-top: 5px;
  margin-bottom: 5px;
}
.form-msg-field{
  width: 95%;
  height: auto;
  border: 1px solid #C0C0C0;
  font-size: 14px;
  color: #000000;
  padding-left: 2%;
}
.select-buttons{
  width: 100%;
  height: 40px;
  border: 1px solid #C0C0C0;
  background-color: #FFFFFF;
  font-size: 14px;
  color: #000000;
  margin-top: 5px;
  margin-bottom: 5px;
}
.delete-buttons{
  width: 120px;
  height: 40px;
  border: 1px solid #C0C0C0;
  border-radius: 5px;
  background-color: #00C0EF;
  font-size: 14px;
  color: #FFFFFF;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.uploadbutt{
  width: 150px;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  color: white;
  border: none;
  border-radius: 5px;
  background-color: #008CBA;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.edit-title{
  width: 250px;
  font-size: 24px;
  font-weight: 500;
  cursor: pointer; 
  color: black; 
  padding: 4px; 
  border-radius: 5px;
  border: 1px solid #C0C0C0;
  background-color: #FFFFFF;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.editbutton{
  font-size: 20px;
  font-weight: 500;
  cursor: pointer; 
  background-color: #FFFFFF;
  height: auto;
  border: 1px solid #C0C0C0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}